import React, { useState } from "react";

const ContactForm02 = () => {
  const [success, setSuccess] = useState("");

  function Submit(e) {
    e.preventDefault();
    const formEle = e.target;
    const formDatab = new FormData(formEle);
    fetch(
      "https://script.google.com/macros/s/AKfycbxKauG6LpQ8xecda62Dp-HWRbqffRpdvLMxYUAMKXtv39zKy_c_8FSc2foJ36eoxONE/exec",
      {
        method: "POST",
        body: formDatab,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      })

      .catch((error) => {
        console.log(error);
      });

    formEle.reset();

    setSuccess("Your message has been submitted successfully!");
  }
  return (
    <form className="contact_form" onSubmit={(e) => Submit(e)} method="post">
      <div className="form-container">
        <div className="row">
          <div className="col-md-6 col-lg-6">
            <div className="form-group">
              <input
                type="text"
                name="Name"
                className="form-control"
                placeholder="Your Name*"
                required=""
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-6">
            <div className="form-group">
              <input
                type="email"
                name="Email"
                className="form-control"
                placeholder="Email Address*"
                required=""
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-6">
            <div className="form-group">
              <input
                type="text"
                name="Phone"
                className="form-control"
                placeholder="Phone No"
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-6">
            <div className="form-group">
              <input
                type="text"
                name="Subject"
                className="form-control"
                placeholder="Subject"
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-12">
            <div className="form-group">
              <textarea
                name="Message"
                className="form-control"
                placeholder="Message Here*"
                required=""
              ></textarea>
            </div>
          </div>
          <div className="col-md-12 col-lg-12">
            <div className="form-group">
              <input
                className="button"
                type="submit"
                value="Send Message"
                name="submit"
              />
            </div>
          </div>
          <p className="text-white" style={{ fontSize: "20px" }}>
            {success}
          </p>
        </div>
      </div>
    </form>
  );
};

export default ContactForm02;
