import React from "react";
import ProjectDetailsContainer from "../../Containers/ProjectContainers/ProjectDetailsContainer";

const ProjectDetails = () => {
  return (
    <>
      <ProjectDetailsContainer />
    </>
  );
};

export default ProjectDetails;
